<template>
  <div class="liveMyWork">
    <el-tabs class="main_content" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="我的文档" name="first">
        <el-table
          :data="tableData"
          style="max-width: 98rem; border-bottom: none"
          empty-text="您还没有发表任何作品~"
        >
          <el-table-column prop="title" label="标题"> </el-table-column>
          <el-table-column prop="text.length" label="字数"> </el-table-column>
          <el-table-column prop="time" label="最近打开"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <!-- <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              > -->
              <el-button type="text" size="small" @click="amend(scope)"
                >编辑</el-button
              >
              <el-button type="text" size="small" @click="del(scope)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <!-- <el-tab-pane label="生成历史" name="second"> </el-tab-pane> -->
    </el-tabs>

    <div
      class="empty"
      @click="$router.push('/Workbench?workID=1')"
      v-show="!tableData.length ? true : false"
    >
      <img src="../assets/image/live_empty.png" alt="" />
    </div>
    <!-- 分页组件 -->
    <el-pagination
      background
      layout="prev, pager, next"
      @current-change="currentClick"
      :total="this.total"
      style="position: relative; top: 1.25rem; left: -12.5rem"
      v-show="index == '0' && tableData.length"
    >
    </el-pagination>
  </div>
</template>
<script>
import { GetUserWorkByUserId, DeleteUserWorkById } from "../request/api";
import moment from "moment";
export default {
  name: "App",
  data() {
    return {
      activeName: "first",
      index: "0",
      //
      userInfo: {},

      tableData: [],
      total: null,
      page: 1,
    };
  },
  methods: {
    //
    //
    handleClick(row) {
      this.index = row.index;
    },
    del(val) {
      if (confirm("确定要删除此条作品吗?")) {
        alert("删除成功!");
        // console.log(val.row.id);
        DeleteUserWorkById(val.row.id).then(async (res) => {
          if (res.code == 200) {
            let list = this.tableData.filter((item) => item.id !== val.row.id);

            // console.log(this.page);
            if (!list.length) {
              this.currentClick(this.page - 1);
            } else {
              this.currentClick(this.page);
            }

            // console.log(list);
            // this.tableData = [...list];
            // console.log(this.tableData);
            // this.$store.commit("writeList", [...list]);
          } else {
            this.$message({
              type: "warning",
              message: res.message,
            });
          }
        });
      }
    },
    amend(val) {
      this.$router.push({
        name: "workamend",
        query: { id: val.row.id },
      });
      // console.log(val);
    },

    //分页点击事件

    currentClick(page) {
      this.page = page;
      // console.log(this.page + "当前页");
      let pageIndex = (page - 1) * 10;
      let getWorkList = {
        pageIndex: pageIndex,
        pageNum: 10,
        userId: this.userInfo.id,
      };
      // console.log(getWorkList);
      GetUserWorkByUserId({ ...getWorkList }).then((res) => {
        // console.log(res);
        let arr = [...res.result.data];
        this.total = Math.ceil(res.result.total / 10) * 10;
        arr.map((item) => {
          item.time = moment(new Date(item.updateTime)).format(
            "YYYY-MM-DD HH:mm"
          );
        });
        this.tableData = [...arr];
        // this.$store.commit("writeTotal", res.result.total);
        this.$store.commit("writeList", [...arr]);
        // sessionStorage.setItem("writeList", JSON.stringify([...arr]));
      });
    },
  },
  created() {
    this.userInfo = this.$store.state.userinfo.userInfo;
    this.total = Math.ceil(this.$store.state.writeTotal / 10) * 10;
    // console.log(this.userInfo);
    let getWorkList = {
      pageIndex: 0,
      pageNum: 10,
      userId: this.userInfo.id,
    };

    if (this.$store.state.writeList.length != 0) {
      // console.log(this.$store.state.writeList);
      this.tableData = [...this.$store.state.writeList];
      // this.total = Math.ceil(res.result.total / 10) * 10;
    } else {
      GetUserWorkByUserId({ ...getWorkList }).then((res) => {
        // console.log(res);
        //排序操作
        this.total = Math.ceil(res.result.total / 10) * 10;
        let arr = [...res.result.data];
        arr.map((item) => {
          item.time = moment(new Date(item.updateTime)).format(
            "YYYY-MM-DD HH:mm"
          );
        });
        //
        //存vuex全局
        this.$store.commit("writeList", [...arr]);
        sessionStorage.setItem("writeList", JSON.stringify([...arr]));
        // let list = sessionStorage.getItem("writeList");
        // console.log(JSON.parse(list));
        this.tableData = [...this.$store.state.writeList];
      });
    }

    // GetUserWorkByUserId({ ...getWorkList }).then((res) => {
    //   // console.log(res);
    //   //排序操作
    //   this.total = Math.ceil(res.result.total / 10) * 10;
    //   let arr = [...res.result.data];
    //   arr.map((item) => {
    //     item.time = moment(new Date(item.updateTime)).format(
    //       "YYYY-MM-DD HH:mm"
    //     );
    //   });
    //   //
    //   //存vuex全局
    //   this.$store.commit("writeList", [...arr]);
    //   sessionStorage.setItem("writeList", JSON.stringify([...arr]));
    //   // let list = sessionStorage.getItem("writeList");
    //   // console.log(JSON.parse(list));
    //   this.tableData = [...this.$store.state.writeList];
    // });
  },
  watch: {
    "$store.state.writeList": {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) { 
        if (val != oldVal) {
          this.tableData = [...val];
        }
      },
      deep: true, //true 深度监听
    },
    $route: {
      handler: function (Val) {
        // console.log(Val);
        if (Val.name == "livemywoke") {
          let getWorkList = {
            pageIndex: 0,
            pageNum: 10,
            userId: this.userInfo.id,
          };
          GetUserWorkByUserId({ ...getWorkList }).then((res) => {
            this.total = Math.ceil(res.result.total / 10) * 10;
            // console.log(this.total);
          });
        }
      },
      deep: true,
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang='scss' scoped>
.liveMyWork {
  position: relative;
}
.el-table::before {
  background-color: transparent;
}

.empty {
  cursor: pointer;
}
</style>
